import request from '@/utils/request';
import store from "@/store/index.js";

export function getBrandByDomain(domain) {
    return request({
        method: 'post',
        url: '/api/website-manager/manager/getBrandByDomain',
        data:{domain}
    })
}
export function getPageInfo(brandTypeName) {
    return request({
        method: 'get',
        url: '/api/website-manager/manager',
        params:{
            imgBrandType:brandTypeName, //品牌
            imgType: 'Website' //注册页 
        }
    })
}

// 校验当前用户是否注册过系统
export function checkRegisterUser(userEmail) {
    return request({
        method: 'post',
        headers:{'Content-Type': 'multipart/form-data'},
        url: '/api/website-manager/register/checkRegisterUser',
        data:{brandType:store.state.brandTypeName,userEmail}
    })
}

// 获取验证码
export function sendVerificationCode(emailBox,operation='register') {
    return request({
        method: 'post',
        headers:{'Content-Type': 'multipart/form-data'},
        url: '/api/website-manager/register/verification/code',
        data: {
            emailBox,
            brandType:store.state.brandTypeName,
            operation
        }
    })
}

// 注册接口
export function registerInsertPO(data) {
    return request({
        method: 'post',
        url: '/api/website-manager/register/insertPO/'+data.VerificationCode,
        data:{...data,userBrandType:store.state.brandTypeName}
    })
}

// 注册接口
export function updateOrderSchedule(data) {
    return request({
        method: 'post',
        url: '/api/website-manager/manager/updateOrderSchedule',
        data:{...data}
    })
}

// 确认地址信息
export function submitGiftMessage(data) {
    return request({
        method: 'post',
        url: '/api/website-manager/gift/submitGiftMessage',
        data:{...data}
    })
}
// 登录
export function toLoginRegister(data) {
    return request({
        headers:{'Content-Type': 'multipart/form-data'},
        method: 'post',
        url: '/api/website-manager/register/login',
        data:{...data,brandType:store.state.brandTypeName}
    })
}

// 获取warrantylist
export function queryRegisterUser() {
    return request({
        method: 'get',
        url: '/api/website-manager/gift/queryRegisterUser'
    })
}

// 注册接口
export function modifyPassWord(data) {
    return request({
        method: 'post',
        url: '/api/website-manager/register/modifyPassWord/'+data.VerificationCode,
        data:{...data,userBrandType:store.state.brandTypeName}
    })
}