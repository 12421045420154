<template>
  <div class="brand" v-if="brandInfo&&brandVo">
    <!-- logo&跳转 -->
    <div class="brand-header">
      <div class="logo">
        <img class="lazy" :src="brandInfo.brandLogoUrl" alt="" />
      </div>
      <div class="link" @click="goUrl(brandInfo.brandUrl)">
        <span>Go to {{ brandType }}</span>
        <span>
          <i class="iconfont icon-zhifeiji"></i>
        </span>
      </div>
    </div>
    <!-- logo&跳转 -->

    <div class="brand-body">
      <!-- logo&跳转 -->
      <div class="banner">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in brandVo.EXHIBITION"
            :key="index"
            @click="goUrl(item.imgDirectUrl)"
          >
            <img class="lazy" :src="item.imgUrl" />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <!-- logo&跳转 -->

      <!-- 宣传图 -->
      <div class="brand-propaganda">
        <div
          class="propaganda-item"
          v-for="(item, index) in brandVo.PROPAGANDA"
          :key="index"
          @click="goUrl(item.imgDirectUrl)"
        >
          <img class="lazy" :src="item.imgUrl" alt="" />
        </div>
      </div>
      <!-- 宣传图 -->

      <!-- 性能图 -->
      <div class="brand-performance">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in brandVo.PERFORMANCE"
            :key="index"
          >
            <img class="lazy" :src="item.imgUrl" />
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
      </div>
      <!-- 性能图 -->

      <!-- 品牌故事 -->
      <div class="brand-story">
        <div class="title">The Story of {{ brandType }}</div>
        <div class="container">{{ brandInfo.brandDesc }}</div>
        <div class="story-img">
          <img class="lazy" :src="brandInfo.brandImgUrl" alt="" />
        </div>

        <div class="story-btn" @click="goUrl(brandInfo.brandUrl)">
          <i class="iconfont icon-zhifeiji"></i>&ensp;
          <span>Go to {{ brandType }}</span>
        </div>
      </div>
      <!-- 品牌故事 -->

      <!-- 产品模块 -->
      <div class="brand-product">
        <div class="brand-product-title">
          <div>Our products</div>
          <div @click="goUrl(brandInfo.brandUrl)">
            <span>show all</span>
            <i class="iconfont icon-youjiantou"></i>
          </div>
        </div>
        <div class="brand-product-container">
          <div class="brand-product-item" v-for="(item, index) in brandVo.PRODUCT"
            :key="index">
            <div class="top">
              <div class="pic">
                <img class="lazy" :src="item.imgUrl" alt="" />
              </div>
              <div class="title">{{item.imgDesc}}</div>
            </div>
            <div
              class="price"
              @click="goUrl(item.imgDirectUrl)"
            >
              <div>{{item.imgPrice}}</div>
              <div>
                <i class="iconfont icon-24gf-bag"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品模块 -->

      <!-- 亚马逊宣传图 -->
      <div class="brand-amazon">
        <img class="lazy" :src="brandInfo.brandDirectImgUrl" alt="" />

        <div class="amazon-btn" @click="goUrl(brandInfo.brandDirectUrl)">
          Get it on Amazon
        </div>
      </div>
      <!-- 亚马逊宣传图 -->
    </div>

    <RegisterDialog ref="registerDialog" @success="handleRegisterSuccess" @registed="handleRegisted"/>

    <CongrateDialog ref="congrateDialog" @success="handleWantConfirm"/>

    <CommentDialog ref="commentDialog" @success="handleToConfirm"/>

    <CommentConfirm ref="commentConfirm" @success="handleToAmazon"/>

    <AmazonDialog ref="amazonDialog" @success="handleToAddress"/>

    <ConfirmAddress ref="confirmAddress" @success="handleToFinshed"/>

    <FinishedDialog ref="finishedDialog"/>

    <RegistedTips ref="registedTips"/>

    <!-- 注册按钮 -->
    <div id="touchMove" class="touchMove">
      <i class="iconfont icon-c-xinrenzhuce"></i>
      <div>Register</div>
    </div>
    <!-- 注册按钮 -->

    
    <div id="warrantyBtn" class="warrantyBtn">
      <i class="iconfont icon-invoice_warranty_fill"></i>
      <div>Warranty</div>
    </div>
  </div>
</template>

<script>


import {getPageInfo,getBrandByDomain} from "@/api/index.js";
import RegisterDialog from "./components/RegisterDialog.vue";
import CongrateDialog from "./components/CongrateDialog.vue";
import CommentDialog from "./components/CommentDialog.vue";
import CommentConfirm from "./components/CommentConfirm.vue";
import AmazonDialog from "./components/AmazonDialog.vue";
import FinishedDialog from "./components/FinishedDialog.vue";
import ConfirmAddress from "./components/ConfirmAddress.vue";
import RegistedTips from "./components/RegistedTips.vue";
// import $ from 'jquery';
import Swiper from 'swiper';
export default {
    components:{
      RegisterDialog,
      CongrateDialog,
      CommentDialog,
      CommentConfirm,
      AmazonDialog,
      FinishedDialog,
      ConfirmAddress,
      RegistedTips
    },
    data() {
        return {
            brandInfo:null,
            brandVo:null,
            // brandType:'Cirtek',
            visibility: false,
            time: 5000,
            color:'blue'
        };
    },

    computed:{
      brandType(){
        return this.$store.state.brandTypeName
      }
    },

    methods:{
      hoverBtnClick(){
        this.$refs.registerDialog.openDialog()
        this.$store.dispatch('openSnackbar',{
          msg:'Sorry,You have been got the Gift!',
          color:'primary'
        })
      },
      goUrl(url){
        window.open(url,"_target")
      },

      // 注册按钮
      suspensionBall(dragId) {
        let self=this;
        var startEvt, moveEvt, endEvt
        // 判断是否支持触摸事件
        if ('ontouchstart' in window) {
          startEvt = 'touchstart'
          moveEvt = 'touchmove'
          endEvt = 'touchend'
        } else {
          startEvt = 'mousedown'
          moveEvt = 'mousemove'
          endEvt = 'mouseup'
        }
        // 获取元素
        var drag = document.getElementById(dragId)
        drag.style.position = 'fixed'
        drag.style.cursor = 'move'
        // 标记是拖曳还是点击
        var isClick = true
        var disX, disY, left, top, starX, starY

        drag.addEventListener(startEvt, function (e) {
          // 阻止页面的滚动，缩放
          e.preventDefault()
          // 兼容IE浏览器
          var e = e || window.event
          isClick = true
          // 手指按下时的坐标
          starX = e.touches ? e.touches[0].clientX : e.clientX
          starY = e.touches ? e.touches[0].clientY : e.clientY
          // 手指相对于拖动元素左上角的位置
          disX = starX - drag.offsetLeft
          disY = starY - drag.offsetTop
          // 按下之后才监听后续事件
          document.addEventListener(moveEvt, moveFun)
          document.addEventListener(endEvt, endFun)
        })

        function moveFun(e) {
          // 兼容IE浏览器
          var e = e || window.event
          // 防止触摸不灵敏，拖动距离大于20像素就认为不是点击，小于20就认为是点击跳转
          if (
            Math.abs(starX - (e.touches ? e.touches[0].clientX : e.clientX)) > 20 ||
            Math.abs(starY - (e.touches ? e.touches[0].clientY : e.clientY)) > 20
          ) {
            isClick = false
          }
          left = (e.touches ? e.touches[0].clientX : e.clientX) - disX
          top = (e.touches ? e.touches[0].clientY : e.clientY) - disY
          // 限制拖拽的X范围，不能拖出屏幕
          if (left < 0) {
            left = 0
          } else if (left > document.documentElement.clientWidth - drag.offsetWidth) {
            left = document.documentElement.clientWidth - drag.offsetWidth
          }
          // 限制拖拽的Y范围，不能拖出屏幕
          if (top < 0) {
            top = 0
          } else if (top > document.documentElement.clientHeight - drag.offsetHeight) {
            top = document.documentElement.clientHeight - drag.offsetHeight
          }
          drag.style.left = left + 'px'
          drag.style.top = top + 'px'
        }

        function endFun(e) {
          document.removeEventListener(moveEvt, moveFun)
          document.removeEventListener(endEvt, endFun)
          if (isClick) {
            if(dragId=='touchMove'){
              self.$refs.registerDialog.openDialog()
            }else{
              self.$router.push('/warranty')
            }
          }
        }
      },
      // 注册成功回调
      handleRegisterSuccess(){
        this.$refs.congrateDialog.visible=true;
      },

      // 确认获取礼物
      handleWantConfirm(){
        this.$refs.commentDialog.visible=true;
      },
      // 打开确认评论弹框
      handleToConfirm(num){
        // 大于三颗星需要评论,小于三颗星直接确认收货信息
        if(num>3){
          this.$refs.commentConfirm.visible=true;
        }else{
          this.$refs.confirmAddress.openDialog();
        }
      },

      // 跳转亚马逊后打开确认亚马逊窗口
      handleToAmazon(){
        this.$refs.amazonDialog.visible=true;
      },

      handleToAddress(){
        this.$refs.confirmAddress.openDialog();
      },

      handleToFinshed(){
        this.$refs.finishedDialog.visible=true;
      },

      // 已经注册过提示框
      handleRegisted(){
        this.$refs.registedTips.visible=true;
      }
    },

    created(){
      // let brandType=""
      // if(window.location.host.split('.')[0].indexOf('localhost')>-1||(window.location.host.indexOf('192.168.1.86')>-1)){
      //   brandType='Cirteklynx'
      // }else{
      //   let _url=window.location.host.split('.')[0]
      //   brandType=_url.trim().toLowerCase().replace(_url[0], _url[0].toUpperCase())
      // }
      // this.brandType=brandType;
    },

    mounted(){
      let host=window.location.host;
      getBrandByDomain(host).then((_res)=>{
        getPageInfo(_res.data).then((res)=>{
          this.$store.commit('SET_BRAND_EMAIL',res.data.BRAND[0].brandMailbox)
          this.$store.commit('SET_BRAND_TYPE_NAME',_res.data)
          this.brandInfo=res.data.BRAND[0]
          this.brandVo=res.data
            this.$nextTick(()=>{
              new Swiper('.banner', {
                  pagination: '.swiper-pagination',
                  paginationType: 'bullets',
                  paginationClickable: true
              });

              new Swiper('.brand-performance', {
                  pagination: '.swiper-pagination',
                  slidesPerView: 2,
                  paginationClickable: true,
                  spaceBetween: 10,
                  grabCursor: true
              });

              this.suspensionBall("touchMove")
              this.suspensionBall("warrantyBtn")
            })
          })
      })
      

      

    }
};
</script>

<style lang="scss" scoped>
.brand{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.brand-header{
  width: 100%;
  height: 1rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 0.16rem 0.02rem rgba(0, 0, 0, 0.1);
  opacity: 1;
  display: flex;
  justify-content: space-between;
  font-size: 0.28rem;
  border-top: 0.02rem solid #F3F3F3;
  box-sizing: border-box;
  &>div:first-child{
    margin: 0.3rem 0.24rem;
    width: 1.46rem;
    height: 0.4rem;
    img{
      width: 100%;
      height: 100%;
    }
  }
  &>div:last-child{
    font-size: 0.28rem;
    font-weight: 400;
    color: #000000;
    padding: 0.28rem;
    &>span:first-child{
      margin-right: 0.16rem;
      line-height: 0.32rem;
    }
    .iconfont{
      font-size: 0.32rem;
      color: #0162BD;
      line-height: 0.32rem;
    }
  }
}
.brand-body{
  padding: 0.24rem;
  box-sizing: border-box;
  position: relative;
  flex: 1;
  overflow-y: auto;
  color: #000000;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  /* 定义滚动条样式 */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: rgba(29,29,38,.7);
  }
  /*定义滚动条轨道*/
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
    border-radius: 10px;
    background: rgba(29,29,38,.7);
  }
  /*定义滑块*/
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 0px rgba(118, 118, 118, 0.5);
    background-color: rgba(118, 118, 118, 0.5);
}
}

.banner {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 0.16rem;

  .swiper-slide {
    width: 100%;
    text-align: center;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.16rem;
  }
  .swiper-pagination-bullet{
    width: 0.16rem;
    height: 0.16rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 0.16rem 0.02rem rgba(0, 0, 0, 0.1);
    opacity: 1;
  }
  .swiper-pagination-bullet-active{
    width: 0.6rem;
    height: 0.16rem;
    background: #0162BD;
    box-shadow: 0px 0px 0.16rem 0.02rem rgba(0, 0, 0, 0.1);
    border-radius: 0.1rem;
    opacity: 1;
    transition: 200ms;
  }
  .swiper-pagination-bullets{
    bottom: 0!important;
  }
}

.brand-performance{
  width: 100%;
  height: 5.4rem;
  overflow: hidden;
  position: relative;
  padding-bottom: 0.8rem;
  .swiper-slide{
    background: #C4C4C4;
    border-radius: 0.16rem;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .swiper-pagination-bullet{
    width: 0.2rem;
    height: 0.2rem;
    background: #DADADA;
    box-shadow: 0px 0px 0.16rem 0.02rem rgba(0, 0, 0, 0.1);
    opacity: 1;
  }
  .swiper-pagination-bullet-active{
    background: #0162BD;
  }
}

.brand-propaganda{
  width: 100%;
  .propaganda-item{
    width: 100%;
    background: #C4C4C4;
    border-radius: 0.16rem;
    opacity: 1;
    height: auto;
    overflow: hidden;
    margin-top:0.22rem;
    position: relative;
    img{
      width: 100%;
    }
  }
}

.brand-story{
  background: #F3F3F3;
  border-radius: 0.16rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0.6rem 0.44rem 0.5rem;
  .title{
    font-size: 0.8rem!important;
    font-weight: bold;
    line-height: 0.9rem;
  }
  .container{
    font-size: 0.36rem;
    color: #5E5E5E;
    line-height: 0.5rem;
    margin-top: 0.24rem;
    white-space: pre-wrap;
  }
  .story-img{
    margin-top: 0.4rem;
    border-radius: 0.16rem;
    overflow: hidden;
    img{
      width: 100%;
      border-radius: 0.16rem;
    }
  }
  .story-btn{
    width: 3.86rem;
    height: 1rem;
    line-height: 1rem;
    background: #0162BD;
    border-radius: 1.22rem;
    opacity: 1;
    color: #fff;
    font-size: 0.32rem;
    text-align: center;
    margin: 0.5rem auto 0;
  }
}

.brand-performance{
  height: 5.96rem;
  margin-top: 0.22rem;
}

.brand-product{
  .brand-product-title{
    display: flex;
    justify-content: space-between;
    margin-top: 0.22rem;
    height: 0.5rem;
    &>div:first-child{
      font-size: 0.48rem;
      line-height: 1;
      font-weight: 500;
      color: #000000;
    }
    &>div:last-child{
      font-size: 0.32rem;
      line-height: 1;
      color: #ADADAD;
      align-self: flex-end;
    }
  }
  .brand-product-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .brand-product-item{
      width: 49%;
      box-sizing: border-box;
      background: #F1F5FC;
      border-radius: 0.16rem;
      overflow: hidden;
      margin:0.24rem 0 0 0;
      // &:nth-child(2n){
      //   margin-right: 0;
      // }
      .top{
        padding: 0.2rem 0.2rem 0;
      }
      .pic{
        width: 100%;
        height: 3.14rem;
        border-radius: 0.16rem;
        overflow: hidden;
        background: #fff;
        box-shadow: 0px 0.16rem 0.24rem 0.02rem rgba(151, 151, 151, 0.1);
        img{
          width: 100%;
          height: 100%;
        }
      }
      .title{
        width: 100%;
        height: 0.72rem;
        font-size: 0.28rem!important;
        line-height: 0.36rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-top: 0.16rem;
      }
      .price{
        display: flex;
        justify-content: space-between;
        &>div:first-child{
          font-weight: 500;
          color: #0162BD;
          font-size: 0.4rem;
          padding-left: 0.2rem;
          line-height: 0.74rem;
        }
        &>div:last-child{
          width: 0.74rem;
          height: 0.74rem;
          background: #0162BD;
          border-radius: 0.26rem 0 0;
          color: #fff;
          text-align: center;
          line-height: 0.74rem;
          font-size: 0.24rem;
        }
      }
    }
  }
}

.brand-amazon{
  width: 100%;
  border-radius: 0.16rem;
  overflow: hidden;
  margin-top: 0.24rem;
  position: relative;
  img{
    width: 100%;
    border-radius: 0.16rem;
  }
  .amazon-btn{
    position: absolute;
    bottom: 0.6rem;
    left: 0.34rem;
    width: 3.3rem;
    height: 1rem;
    background: #FFFFFF;
    border-radius: 1.06rem;
    opacity: 1;
    text-align: center;
    line-height: 1rem;
    font-size: 0.32rem;
    color: #0162BD;
  }
}

.touchMove{
  width: 0.96rem;
  height: 0.96rem;
  background: #0162BD;
  box-shadow: 0px 0px 0.16rem 0.02rem rgba(0, 0, 0, 0.1);
  opacity: 1;
  border: 0.02rem solid #FFFFFF;
  border-radius: 50%;
  position: fixed;
  left: 80%;
  bottom: 2.6rem;
  z-index: 5;
  color: #fff;
  text-align: center;
  line-height: 1;
  display: flex;
  flex-direction: column;
  i{
    font-size: 0.4rem;
    line-height: 0.4rem;
    margin-top: 0.14rem;
  }
  div{
    text-align: center;
    font-size: 0.28rem;
    transform: scale(0.5) translateX(-0.1rem);
  }
}

.warrantyBtn{
  width: 0.96rem;
  height: 0.96rem;
  background: #0162BD;
  box-shadow: 0px 0px 0.16rem 0.02rem rgba(0, 0, 0, 0.1);
  opacity: 1;
  border: 0.02rem solid #FFFFFF;
  border-radius: 50%;
  position: fixed;
  left: 80%;
  bottom: 1.2rem;
  z-index: 5;
  color: #fff;
  text-align: center;
  line-height: 1;
  display: flex;
  flex-direction: column;
  i{
    font-size: 0.4rem;
    line-height: 0.4rem;
    margin-top: 0.14rem;
  }
  div{
    text-align: center;
    font-size: 0.24rem;
    transform: scale(0.5) translateX(-0.1rem);
  }
}

@keyframes turn{
  0%{-webkit-transform:rotate(0deg);}
  25%{-webkit-transform:rotate(90deg);}
  50%{-webkit-transform:rotate(180deg);}
  75%{-webkit-transform:rotate(270deg);}
  100%{-webkit-transform:rotate(360deg);}
}
.loading{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11;
  .loading-tips{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    .spinner {
      width: 1.2rem;
      height: 1.2rem;
      background-color: #67CF22;
      -webkit-animation: rotateplane 1.2s infinite ease-in-out;
      animation: rotateplane 1.2s infinite ease-in-out;
      margin: 0 auto;
    }

    .loading-text{
      text-align: 'center';
      color: #fff;
      margin-top: 0.6rem;
      font-size: 0.4rem;
    }
     
    @-webkit-keyframes rotateplane {
      0% { -webkit-transform: perspective(2.4rem) }
      50% { -webkit-transform: perspective(2.4rem) rotateY(180deg) }
      100% { -webkit-transform: perspective(2.4rem) rotateY(180deg)  rotateX(180deg) }
    }
     
    @keyframes rotateplane {
      0% {
        transform: perspective(2.4rem) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(2.4rem) rotateX(0deg) rotateY(0deg)
      } 50% {
        transform: perspective(2.4rem) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(2.4rem) rotateX(-180.1deg) rotateY(0deg)
      } 100% {
        transform: perspective(2.4rem) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(2.4rem) rotateX(-180deg) rotateY(-179.9deg);
      }
    }
  }
}

@media(min-width: 800px) {
  .brand{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .brand-header{
    width: 100%;
    height: 1*51.282px;
    background: #FFFFFF;
    box-shadow: 0px 0px 0.16*51.282px 0.02*51.282px rgba(0, 0, 0, 0.1);
    opacity: 1;
    display: flex;
    justify-content: space-between;
    font-size: 0.28*51.282px;
    border-top: 0.02*51.282px solid #F3F3F3;
    box-sizing: border-box;
    &>div:first-child{
      margin: 0.3*51.282px 0.24*51.282px;
      width: 1.46*51.282px;
      height: 0.4*51.282px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    &>div:last-child{
      font-size: 0.28*51.282px;
      font-weight: 400;
      color: #000000;
      padding: 0.28*51.282px;
      &>span:first-child{
        margin-right: 0.16*51.282px;
        line-height: 0.32*51.282px;
      }
      .iconfont{
        font-size: 0.32*51.282px;
        color: #0162BD;
        line-height: 0.32*51.282px;
      }
    }
  }
  .brand-body{
    padding: 0.24*51.282px;
    box-sizing: border-box;
    position: relative;
    flex: 1;
    overflow-y: auto;
    color: #000000;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    /* 定义滚动条样式 */
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background: rgba(29,29,38,.7);
    }
    /*定义滚动条轨道*/
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
      border-radius: 10px;
      background: rgba(29,29,38,.7);
    }
    /*定义滑块*/
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 0px rgba(118, 118, 118, 0.5);
      background-color: rgba(118, 118, 118, 0.5);
    }
  }

  .banner {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 0.16*51.282px;

    .swiper-slide {
      width: 100%;
      text-align: center;
      background: #fff;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.16*51.282px;
    }
    .swiper-pagination-bullet{
      width: 0.16*51.282px;
      height: 0.16*51.282px;
      background: #FFFFFF;
      box-shadow: 0px 0px 0.16*51.282px 0.02*51.282px rgba(0, 0, 0, 0.1);
      opacity: 1;
    }
    .swiper-pagination-bullet-active{
      width: 0.6*51.282px;
      height: 0.16*51.282px;
      background: #0162BD;
      box-shadow: 0px 0px 0.16*51.282px 0.02*51.282px rgba(0, 0, 0, 0.1);
      border-radius: 0.1*51.282px;
      opacity: 1;
      transition: 200ms;
    }
    .swiper-pagination-bullets{
      bottom: 0!important;
    }
  }

  .brand-performance{
    width: 100%;
    height: 5.4*51.282px;
    overflow: hidden;
    position: relative;
    padding-bottom: 0.8*51.282px;
    .swiper-slide{
      background: #C4C4C4;
      border-radius: 0.16*51.282px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    
    .swiper-pagination-bullet{
      width: 0.2*51.282px;
      height: 0.2*51.282px;
      background: #DADADA;
      box-shadow: 0px 0px 0.16*51.282px 0.02*51.282px rgba(0, 0, 0, 0.1);
      opacity: 1;
    }
    .swiper-pagination-bullet-active{
      background: #0162BD;
    }
  }

  .brand-propaganda{
    width: 100%;
    .propaganda-item{
      width: 100%;
      background: #C4C4C4;
      border-radius: 0.16*51.282px;
      opacity: 1;
      height: auto;
      overflow: hidden;
      margin-top:0.22*51.282px;
      position: relative;
      img{
        width: 100%;
      }
    }
  }

  .brand-story{
    background: #F3F3F3;
    border-radius: 0.16*51.282px;
    width: 100%;
    box-sizing: border-box;
  padding: 0.6*51.282px 0.44*51.282px 0.5*51.282px;
  .title{
    font-size: 0.8*51.282px!important;
    font-weight: bold;
    line-height: 0.9*51.282px;
  }
  .container{
    font-size: 0.36*51.282px;
    color: #5E5E5E;
    line-height: 0.5*51.282px;
    margin-top: 0.24*51.282px;
    white-space: pre-wrap;
  }
  .story-img{
    margin-top: 0.4*51.282px;
    border-radius: 0.16*51.282px;
    overflow: hidden;
    img{
      width: 100%;
      border-radius: 0.16*51.282px;
    }
  }
  .story-btn{
    width: 3.86*51.282px;
    height: 1*51.282px;
    line-height: 1*51.282px;
    background: #0162BD;
    border-radius: 1.22*51.282px;
    opacity: 1;
    color: #fff;
    font-size: 0.32*51.282px;
    text-align: center;
    margin: 0.5*51.282px auto 0;
  }
}

.brand-performance{
  height: 5.96*51.282px;
  margin-top: 0.22*51.282px;
}

.brand-product{
  .brand-product-title{
    display: flex;
    justify-content: space-between;
    margin-top: 0.22*51.282px;
    height: 0.5*51.282px;
    &>div:first-child{
      font-size: 0.48*51.282px;
      line-height: 1;
      font-weight: 500;
      color: #000000;
    }
    &>div:last-child{
      font-size: 0.32*51.282px;
      line-height: 1;
      color: #ADADAD;
      align-self: flex-end;
    }
  }
  .brand-product-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .brand-product-item{
      width: 49%;
      box-sizing: border-box;
      background: #F1F5FC;
      border-radius: 0.16*51.282px;
      overflow: hidden;
      margin:0.24*51.282px 0 0 0;
      // &:nth-child(2n){
      //   margin-right: 0;
      // }
      .top{
        padding: 0.2*51.282px 0.2*51.282px 0;
      }
      .pic{
        width: 100%;
        height: 7*51.282px;
        border-radius: 0.16*51.282px;
        overflow: hidden;
        background: #fff;
        box-shadow: 0px 0.16*51.282px 0.24*51.282px 0.02*51.282px rgba(151, 151, 151, 0.1);
        img{
          width: 100%;
          height: 100%;
        }
      }
      .title{
        width: 100%;
        height: 0.72*51.282px;
        font-size: 0.28*51.282px!important;
        line-height: 0.36*51.282px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-top: 0.16*51.282px;
      }
      .price{
        display: flex;
        justify-content: space-between;
        &>div:first-child{
          font-weight: 500;
          color: #0162BD;
          font-size: 0.4*51.282px;
          padding-left: 0.2*51.282px;
          line-height: 0.74*51.282px;
        }
        &>div:last-child{
          width: 0.74*51.282px;
          height: 0.74*51.282px;
          background: #0162BD;
          border-radius: 0.26*51.282px 0 0;
          color: #fff;
          text-align: center;
          line-height: 0.74*51.282px;
          font-size: 0.24*51.282px;
        }
      }
    }
  }
}

.brand-amazon{
  width: 100%;
  border-radius: 0.16*51.282px;
  overflow: hidden;
  margin-top: 0.24*51.282px;
  position: relative;
  img{
    width: 100%;
    border-radius: 0.16*51.282px;
  }
  .amazon-btn{
    position: absolute;
    bottom: 0.6*51.282px;
    left: 0.34*51.282px;
    width: 3.3*51.282px;
    height: 1*51.282px;
    background: #FFFFFF;
    border-radius: 1.06*51.282px;
    opacity: 1;
    text-align: center;
    line-height: 1*51.282px;
    font-size: 0.32*51.282px;
    color: #0162BD;
  }
}

.touchMove{
  width: 0.96*51.282px;
  height: 0.96*51.282px;
  background: #0162BD;
  box-shadow: 0px 0px 0.16*51.282px 0.02*51.282px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border: 0.02*51.282px solid #FFFFFF;
  border-radius: 50%;
  position: fixed;
  left: 80%;
  bottom: 2.6*51.282px;
  z-index: 5;
  color: #fff;
  text-align: center;
  line-height: 1;
  display: flex;
  flex-direction: column;
  i{
    font-size: 0.4*51.282px;
    line-height: 0.4*51.282px;
    margin-top: 0.14*51.282px;
  }
  div{
    text-align: center;
    font-size: 0.28*51.282px;
    transform: scale(0.5) translateX(-0.1*51.282px);
  }
}

.warrantyBtn{
  width: 0.96*51.282px;
  height: 0.96*51.282px;
  background: #0162BD;
  box-shadow: 0px 0px 0.16*51.282px 0.02*51.282px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border: 0.02*51.282px solid #FFFFFF;
  border-radius: 50%;
  position: fixed;
  left: 80%;
  bottom: 1.2*51.282px;
  z-index: 5;
  color: #fff;
  text-align: center;
  line-height: 1;
  display: flex;
  flex-direction: column;
  i{
    font-size: 0.4*51.282px;
    line-height: 0.4*51.282px;
    margin-top: 0.14*51.282px;
  }
  div{
    text-align: center;
    font-size: 0.24*51.282px;
    transform: scale(0.5) translateX(-0.1*51.282px);
  }
}
}
</style>