<template>
  <v-app>
    <router-view/>
    <Snackbar />
    <Loading />
  </v-app>
</template>

<script>
import Snackbar from "@/components/snackbar.vue";
import Loading from "@/components/loading.vue";
export default {
  name: "App",
  components:{
    Snackbar,
    Loading
  },
  data() {
    return {};
  },

  mounted() {
  },
};
</script>

<style lang="scss" scoped>
$color-pack: false;
@import '~vuetify/src/styles/main.sass';

:deep(.v-btn__content,.v-application){
    font-size: 0.18rem!important;
}
@media(min-width: 800px) {
  :deep(.v-btn__content,.v-application){
      font-size:12px!important;
  }
}
</style>
