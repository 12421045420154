<template>
    <v-overlay z-index="9999" :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
</template>

<script>
    export default {
        data(){
            return {
            
            }
        },
        computed:{
            overlay(){
                return this.$store.state.overlay
            }
        }
    }
</script>