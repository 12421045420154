<template>
    <v-dialog
      transition="dialog-top-transition"
      :max-width="maxDialogWidth"
      persistent
      v-model="visible"
      content-class="congrate-dialog"
    >   
        <div class="congrate-wrapper">
            <div>
                Step 2 of 3: Share Your Experience With Others
            </div>
            <div>
                lt won't take long, just a few seconds.Your opinion will be seen by other customers to help them make a better buying decision. Sincere thanks for your kindness, and come back to this page after review is done.
            </div>
            <div>
                Your Comment
            </div>

            <div class="mt-2">
                <div>
                    <v-rating
                    color="warning"
                    dense
                    hover
                    length="5"
                    :size="maxDialogWidth==='600px'?'50px':'0.9rem'"
                    readonly
                    :value="orderInfo.pointStarNum"
                    ></v-rating>
                </div>
            </div>

            <div>
                <div class="mt-2">
                    <v-textarea
                    :value="orderInfo.review"
                    solo
                    :height="maxDialogWidth==='600px'?'100px':'2rem'"
                    auto-grow
                    :row-height="maxDialogWidth==='600px'?'30px':'0.2rem'"
                    readonly
                    hide-details
                    ></v-textarea>
                </div>
            </div>
            <div class="agent-tips">
                <v-checkbox
                v-model="checkbox"
                dark
                label="I know returning to this page for the final step When Review done."
                ></v-checkbox>
            </div>
            <div class="congrate-footer">
                <div><v-btn rounded block color="warning" @click="onCopy">COPY YOUR COMMENT</v-btn></div>
                <div><v-btn rounded dark block color="warning" :disabled="!isCopied" @click="handleToAmazon">POSTTO Amazon</v-btn></div>
            </div>
            
            <div class="close-icon" @click="visible=false">
                <v-icon color="#fff">mdi-close-thick</v-icon>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import {updateOrderSchedule} from "@/api/index.js";
export default {
    data(){
        return {
            visible: false,
            checkbox: true,
            isCopied: false
        }
    },
    computed: {
        maxDialogWidth() {
            return this.$clientWidth > 800 ? "600px" : "6.4rem";
        },
        orderInfo(){
            return this.$store.state.orderInfo||{
                review:'123123123',
                pointStarNum: 4
            };
        }
    },

    watch:{
        'visible':{
            handler(val){
                if(!val){
                    this.isCopied=false;  
                }
            },
            immediate: true
        }
    },

    methods:{
        copyToClipboard(textToCopy) {
            // navigator clipboard 需要https等安全上下文
            if (navigator.clipboard && window.isSecureContext) {
                // navigator clipboard 向剪贴板写文本
                return navigator.clipboard.writeText(textToCopy);
            } else {
                // 创建text area
                let textArea = document.createElement("textarea");
                textArea.value = textToCopy;
                // 使text area不在viewport，同时设置不可见
                textArea.style.position = "absolute";
                textArea.style.opacity = 0;
                textArea.style.left = "-999999px";
                textArea.style.top = "-999999px";
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                return new Promise((res, rej) => {
                    // 执行复制命令并移除文本框
                    document.execCommand('copy') ? res() : rej();
                    textArea.remove();
                });
            }
        },


        onCopy(){
            this.copyToClipboard(this.orderInfo.review)
            this.$store.dispatch('openSnackbar',{
                msg:`Copied`,
                color:'success'
            })
            this.isCopied=true
        },

        openNewWindow(url) {
            var newWindow = window.open(url, '_blank');
            
            // Check if the new window was blocked by a popup blocker
            if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                // Opening the new window was blocked
                // You can provide an alternative action here, such as opening the URL in the current window
                window.location.href = url;
            }
        },

        handleToAmazon(){
            updateOrderSchedule({
                orderId: this.$store.state.orderInfo.orderId,
                schedule: 4
            }).then((res)=>{
                this.openNewWindow('https://www.amazon.com/review/create-review/error?ie=UTF8&channel=reviews-product&asin='+this.$store.state.orderInfo.asin)
                this.visible=false;
                this.$emit('success')
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .congrate-wrapper{
        padding: 0.8rem 0.4rem 0.5rem;
        font-size: 0.26rem;
        background: rgba(0,0,0,.6);
        color: #fff;
        position: relative;
        font-size: 0.28rem;
        line-height: 1.5;
        &>div:nth-child(1){
            box-sizing: border-box;
            font-size: 0.28rem;
            font-weight: bold;
        }
        &>div:nth-child(2){
            box-sizing: border-box;
            font-size: 0.26rem;
            text-align: center;
            margin-top: 0.2rem;
        }
        &>div:nth-child(3){
            box-sizing: border-box;
            font-size: 0.26rem;
            text-align: center;
        }
        .agent-tips{
            display: flex;
            align-items: flex-start;
        }
        .comment-step{
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding-right: 1rem;
            margin-top: 0.2rem;
            margin-bottom: 0.2rem;
            &>div{
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                border: 1px solid #fff;
                text-align: center;
                line-height: 0.5rem;
                &.active{
                  border: 1px solid #0288d1;
                }
            }
            .v-divider{
                margin: 0 0.2rem;
            }
        }
        .close-icon{
            position: absolute;
            top:0.3rem;
            right:0.3rem;
        }
        .congrate-footer{
            text-align: center;
            &>div:nth-child(1){
                margin-bottom: 0.2rem;
            }
        }
        :deep(.v-textarea textarea){
            line-height: 1.5;
        }
    }

    @media(min-width: 800px) {
        .congrate-wrapper{
            padding: 0.8*51.282px 0.4*51.282px 0.5*51.282px;
            font-size: 0.26*51.282px;
            background: rgba(0,0,0,.6);
            color: #fff;
            position: relative;
            font-size: 0.28*51.282px;
            line-height: 1.5;
            &>div:nth-child(1){
                box-sizing: border-box;
                font-size: 0.28*51.282px;
                font-weight: bold;
            }
            &>div:nth-child(2){
                box-sizing: border-box;
                font-size: 0.26*51.282px;
                text-align: left;
                margin-top: 0.2*51.282px;
            }
            &>div:nth-child(3){
                box-sizing: border-box;
                font-size: 0.26*51.282px;
                text-align: center;
            }
            .agent-tips{
                display: flex;
                align-items: flex-start;
            }
            .comment-step{
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                padding-right: 1*51.282px;
                margin-top: 0.2*51.282px;
                margin-bottom: 0.2*51.282px;
                &>div{
                    width: 0.5*51.282px;
                    height: 0.5*51.282px;
                    border-radius: 50%;
                    border: 1px solid #fff;
                    text-align: center;
                    line-height: 0.5*51.282px;
                    &.active{
                    border: 1px solid #0288d1;
                    }
                }
                .v-divider{
                    margin: 0 0.2*51.282px;
                }
            }
            .close-icon{
                position: absolute;
                top:0.3*51.282px;
                right:0.3*51.282px;
            }
            .congrate-footer{
                text-align: center;
                &>div:nth-child(1){
                    margin-bottom: 0.2*51.282px;
                }
            }
            :deep(.v-textarea textarea){
                line-height: 1.5;
            }
        }
    }
</style>