<template>
    <v-snackbar content-class="custom-font-size" centered text v-model="visible" :color="color">
      <span v-html="$store.state.snackbar.msg"></span>
      <!-- 关闭按钮 -->
      <template v-slot:action="{ attrs }" >
        <v-btn v-bind="attrs" v-if="showClose" icon @click="close" :color="color"
          ><v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </template>
  <script>
  export default {
    data() {
      return {};
    },
    computed: {
      visible() {
        return this.$store.state.snackbar.visible;
      },
      showClose() {
        return this.$store.state.snackbar.showClose;
      },
      color() {
        return this.$store.state.snackbar.color;
      },
    },
    methods: {
      close() {
        this.$store.commit("CLOSE_SNACKBAR");
      },
    },
  };
  </script>

<style lang="scss" scoped>
:deep(.custom-font-size){
    font-size: 0.3rem!important;
    line-height: 1.5;
}
@media(min-width: 800px) {
  :deep(.custom-font-size){
      font-size: 0.3*51.282px!important;
      line-height: 1.5;
  }
}
</style>

  