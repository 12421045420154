<template>
    <v-dialog
      transition="dialog-top-transition"
      :max-width="maxDialogWidth"
      persistent
      v-model="visible"
      content-class="congrate-dialog"
    >   
        <div class="congrate-wrapper">
            <div>
                <div>
                    <v-icon large color="success">mdi-checkbox-marked-circle</v-icon>
                </div>
                <div>
                    <div>Congratulations!</div>
                    <div>Your warranty has already been activated!</div>
                </div>
            </div>
            <v-divider color="#fff"></v-divider>
            <div>
                And you Are Selected to Get Our Exclusive Gift
            </div>

            <div class="card-list">
                <v-row justify="space-between" class="card-list-container">
                    <v-col :cols="$clientWidth < 800?12:4">
                        <v-card class="card-container">
                            <div>Your Order Info</div>
                            <div class="info-item">
                                <div>order ID:</div>
                                <div>{{orderInfo.orderId}}</div>
                            </div>
                            <div class="info-item">
                                <div>Product ASIN:</div>
                                <div>{{orderInfo.asin}}</div>
                            </div>
                            <div class="info-item">
                                <div>Product Name:</div>
                                <div class="product-name">{{orderInfo.productName}}</div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col :cols="$clientWidth < 800?12:4">
                        <v-card class="card-container">
                            <div>What You Can Get</div>
                            <div class="card-img">
                                <v-img :src="orderInfo.picture"></v-img>
                            </div>
                            <div class="gift-title">{{orderInfo.giftName}}</div>
                        </v-card>
                    </v-col>
                    <v-col :cols="$clientWidth < 800?12:4">
                        <v-card class="card-container">
                            <div>About The Free Gift</div>
                            <div>
                                *NO Shipping Charges, NO Hidden Fees, NO Credit Card Required<br/>
                                * 4 Simple Steps to Get It(Generally less than 5 minutes)<br/>
                                * The gift will be shipped in 2-5 working days after you claim it
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <div class="congrate-footer">
                <v-btn :block="false" color="warning" @click="handleWant">I WANT IT</v-btn>
            </div>

            
            <div class="close-icon" @click="visible=false">
                <v-icon color="#fff">mdi-close-thick</v-icon>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import {updateOrderSchedule} from "@/api/index.js";
export default {
    data(){
        return {
            visible: false
        }
    },
    computed: {
        maxDialogWidth() {
            return this.$clientWidth > 800 ? "800px" : "6.4rem";
        },
        orderInfo(){
            return this.$store.state.orderInfo||{
                orderId:'1231231',
                productName: '123123'
            };
        }
    },

    methods:{
        // 确认获取礼物
        handleWant(){
            updateOrderSchedule({
                orderId: this.$store.state.orderInfo.orderId,
                schedule: 2,
                giftId: this.$store.state.orderInfo.id,
                picture: this.$store.state.orderInfo.picture,
                giftName: this.$store.state.orderInfo.giftName,
            }).then((res)=>{
                this.visible=false;
                this.$emit('success')
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.congrate-wrapper{
    padding: 0.5rem 0.3rem;
    font-size: 0.26rem;
    background: rgba(0,0,0,.6);
    color: #fff;
    position: relative;
    
    .close-icon{
        position: absolute;
        top:0.3rem;
        right:0.3rem;
    }
    &>div:nth-child(1){
        display: flex;
        &>div:nth-child(2){
            flex: 1;
            font-size: 0.3rem;
            line-height: 0.4rem;
            margin-left:0.2rem;
        }
    }
    &>div:nth-child(3){
        margin-top: 0.2rem;
        font-size: 0.20rem;
        text-align: center;
    }
    .card-list{
        height: 6rem;
        overflow-y: auto;
        padding: 0 0.4rem;
        margin-top: 0.4rem;
        .card-container{
            padding:0.4rem;
            font-size: 0.24rem;
            .card-img{
                padding: 0 0.6rem;
            }
            &>div:nth-child(1){
                font-weight: bold;
                text-align: center;
                font-size: 0.26rem;
            }
            .info-item{
                &>div:nth-child(1){
                    font-weight: bold;
                }
                &>div:nth-child(2){
                    color: #726f6f;
                }
                .product-name{
                    height: 0.7rem;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    display:-webkit-box;
                    -webkit-line-clamp:2;
                    -webkit-box-orient:vertical;
                }
            }
            .gift-title{
                text-align: center;
            }
        }
    }
    .congrate-footer{
        text-align: center;
        margin-top: 0.4rem;
    }
}
    
@media(min-width: 800px) {
    .congrate-wrapper{
        padding: 0.8*51.282px 0.3*51.282px;
        font-size: 0.26*51.282px;
        background: rgba(0,0,0,.6);
        color: #fff;
        position: relative;
        
        .close-icon{
            position: absolute;
            top:0.3*51.282px;
            right:0.3*51.282px;
        }
        &>div:nth-child(1){
            display: flex;
            margin-bottom: 20px;
            &>div:nth-child(2){
                flex: 1;
                font-size: 0.3*51.282px;
                line-height: 0.4*51.282px;
                margin-left:0.2*51.282px;
            }
        }
        &>div:nth-child(3){
            margin-top: 0.2*51.282px;
            font-size: 0.30*51.282px;
            text-align: center;
        }
        .card-list{
            height: 6*51.282px;
            overflow-y: auto;
            padding: 0 0.4*51.282px;
            margin-top: 0.4*51.282px;
            .card-list-container{
                align-items: stretch;
            }
            .card-container{
                padding:0.4*51.282px;
                font-size: 0.24*51.282px;
                height: 100%;
                .card-img{
                    padding: 0 0.6*51.282px;
                }
                &>div:nth-child(1){
                    font-weight: bold;
                    text-align: center;
                    font-size: 0.26*51.282px;
                }
                .info-item{
                    &>div:nth-child(1){
                        font-weight: bold;
                    }
                    &>div:nth-child(2){
                        color: #726f6f;
                    }
                    .product-name{
                        height: 40px;
                        overflow:hidden;
                        text-overflow:ellipsis;
                        display:-webkit-box;
                        -webkit-line-clamp:2;
                        -webkit-box-orient:vertical;
                    }
                }
                .gift-title{
                    text-align: center;
                }
            }
        }
        .congrate-footer{
            text-align: center;
            margin-top: 0.4*51.282px;
        }
    }
}
</style>