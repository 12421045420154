import Vue from 'vue'
import VueRouter from 'vue-router'
import Register from '../views/register/index.vue'
import OrderStep from '../views/register/order-step.vue'
import Warranty from '../views/register/warranty.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Register',
    component: Register
  },
  {
    path: '/order-step',
    name: 'OrderStep',
    component: OrderStep
  },
  {
    path: '/warranty',
    name: 'Warranty',
    component: Warranty
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
