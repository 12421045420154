import axios from 'axios'
import store from '@/store';
import router from '@/router'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000*60 // request timeout
})
let count=0;
// request interceptor
service.interceptors.request.use(
  config => {
    store.dispatch('tigglerMask',true)
    count++;
    const token=localStorage.getItem('token')
    config.headers['token']=token
    return config
  },
  error => {
    count--;
    if(count==0){
      store.dispatch('tigglerMask',false)
    }
    console.log(error) // for debug
    return Promise.reject("请联系管理员")
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    count--;
    if(count==0){
      store.dispatch('tigglerMask',false)
    }
    const res = response.data
    if(res.code=='000000'||res.code=='920032'||res.code=='920030'||res.code=='920042'){
        return res
    }else{
        store.dispatch('openSnackbar',{
          msg:res.msg||res.desc || 'Error',
          color:'red',
          showClose: true,
          timeout: 5000,
        })
        return Promise.reject(res)
    }
  },
  error => {
    count--;
    if(count==0){
      store.dispatch('tigglerMask',false)
    }
    console.log(error.response) // for debug
    if (error.response.status === 500) {
      store.dispatch('openSnackbar',{
        msg:`System Error,Please Contact us(${store.state.brandMail})`,
        color:'red',
        showClose: true,
        timeout: 5000,
      })
    }else {
        store.dispatch('openSnackbar',{
          msg:`System Error,Please Contact us(${store.state.brandMail})`,
          color:'red',
          showClose: true,
          timeout: 5000,
        })
        return Promise.reject("请联系管理员")
    }

  }
)
export default service

