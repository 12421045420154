<template>
     <v-dialog
      transition="dialog-top-transition"
      :max-width="maxDialogWidth"
      persistent
      v-model="visible"
      content-class="congrate-dialog"
    >   
    
      <div class="congrate-wrapper">
        <div>
            Step 2 of 3: Confirm Your Information
        </div>
        <div v-if="$clientWidth < 800" class="comment-step">
            <div class="active">1</div>
            <v-divider color="#fff"></v-divider>
            <div class="active">2</div>
            <v-divider color="#fff"></v-divider>
            <div>3</div>
        </div>

        <v-row justify="space-between" class="card-list-container">
            <v-col v-if="$clientWidth > 800" :cols="6" style="display: flex;align-items: center;">
              <v-card class="card-container">
                  <div>What You Can Get</div>
                  <div class="card-img">
                      <v-img :src="orderInfo.picture"></v-img>
                  </div>
                  <div class="gift-title">{{orderInfo.giftName}}</div>
              </v-card>
            </v-col>
            <v-col :cols="$clientWidth < 800?12:6">
              <div v-if="$clientWidth > 800" class="comment-step">
                  <div class="active">1</div>
                  <v-divider color="#fff"></v-divider>
                  <div class="active">2</div>
                  <v-divider color="#fff"></v-divider>
                  <div>3</div>
              </div>
              <v-card class="register-container">
                  <div class="register-wraperr pl-6 pr-6">
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field
                        v-model="form.firstName"
                        :maxlength="30"
                        :rules="firstRules"
                        label="Full Name"
                        hide-details="auto"
                        placeholder="Please enter"
                        required
                        ></v-text-field>
                        <!-- <v-text-field
                        v-model="form.lastName"
                        :maxlength="30"
                        :rules="lastRules"
                        label="Last Name"
                        hide-details="auto"
                        placeholder="Please enter"
                        required
                        ></v-text-field> -->
                        <v-text-field
                        v-model="form.email"
                        :maxlength="30"
                        :rules="emailRules"
                        label="Email"
                        hide-details="auto"
                        validate-on-blur
                        placeholder="Please enter"
                        required
                        ></v-text-field>
                        <v-text-field
                        v-model="form.addressLine1"
                        :maxlength="30"
                        :rules="addressRules"
                        label="Address Line"
                        hide-details="auto"
                        placeholder="Please enter"
                        required
                        v-if="orderInfo.isDisplayAddress"
                        ></v-text-field>
                        <!-- <v-text-field
                        v-model="form.addressLine2"
                        :maxlength="30"
                        label="Address Line2"
                        hide-details="auto"
                        placeholder="Please enter"
                        v-if="orderInfo.isDisplayAddress"
                        ></v-text-field> -->
                        <v-text-field
                        v-model="form.country"
                        :maxlength="30"
                        :rules="countryRules"
                        label="Country"
                        hide-details="auto"
                        placeholder="Please enter"
                        v-if="orderInfo.isDisplayAddress"
                        required
                        ></v-text-field>
                        <v-text-field
                        v-model="form.state"
                        :maxlength="30"
                        :rules="stateRules"
                        label="State/Territory"
                        hide-details="auto"
                        placeholder="Please enter"
                        required
                        v-if="orderInfo.isDisplayAddress"
                        ></v-text-field>
                        <v-text-field
                        v-model="form.city"
                        :maxlength="30"
                        :rules="cityRules"
                        label="City"
                        hide-details="auto"
                        placeholder="Please enter"
                        required
                        v-if="orderInfo.isDisplayAddress"
                        ></v-text-field>
                        <v-text-field
                        v-model="form.zip"
                        :maxlength="30"
                        :rules="zipCodeRules"
                        label="Zip Code"
                        hide-details="auto"
                        placeholder="Please enter"
                        required
                        v-if="orderInfo.isDisplayAddress"
                        ></v-text-field>
                        <v-text-field
                        v-model="form.phoneNumber"
                        :maxlength="30"
                        :rules="phoneRules"
                        label="Phone Number"
                        hide-details="auto"
                        placeholder="Please enter"
                        ></v-text-field>
                    </v-form>
                  </div>
                </v-card>
            </v-col>
          </v-row>

        
       
      
        <div class="register-footer">
          <div class="register-agreement">
              <div class="address-tips" v-if="orderInfo.isDisplayAddress">Shipping Areas: USA ONLY, excluding remote areas such as Alaska and Hawaii; overseas addresses such as Puerto Rico and Guam; military addresses such as APO/FPO.</div>
              <div class="gift-tip" v-if="orderInfo.isDisplayAddress">Submit your shipping address then We will arrange the gift for you.</div>
          </div>
          <v-btn :disabled="!valid" dark color="primary" block @click="validate">
            Submit
          </v-btn>
        </div>

            
        <div class="close-icon" @click="visible=false">
            <v-icon color="#fff">mdi-close-thick</v-icon>
        </div>
      </div>
    </v-dialog>
  </template>
  
  <script>
  import { submitGiftMessage} from "@/api/index.js";
  export default {
    data() {
      return {
        visible: false,
        valid: true,
        form: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          country: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
          email:'',
          state: "",
          zip: "",
        },
        firstRules: [(v) => !!v || "Full Name cannot be empty"],
        lastRules: [(v) => !!v || "Last Name cannot be empty"],
        addressRules: [(v) => !!v || "Address cannot be empty"],
        countryRules: [(v) => !!v || "Country cannot be empty"],
        stateRules: [(v) => !!v || "State/Territory cannot be empty"],
        cityRules: [(v) => !!v || "City cannot be empty"],
        zipCodeRules: [(v) => !!v || "Zip Code cannot be empty"],
        emailRules: [
          (v) => !!v || "email cannot be empty",
          (v) =>
            /^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9]+$/.test(v) ||
            "the email format is incorrect",
        ],
        pwRules: [
          (v) => !!v || "password cannot be empty",
          (v) => (v && v.length == 6) || "password should be 6 characters",
        ],
        phoneRules: [
          (v) => (!v||/^[0-9-+()]+$/.test(v)) || "Phone Number is uncorrect",
        ]
      };
    },

    watch:{
      'visible':{
        handler(val){
          if(!val){
            this.form={
              addressLine1: "",
              addressLine2: "",
              city: "",
              country: "",
              firstName: "",
              lastName: "",
              phoneNumber: "",
              email:'',
              state: "",
              zip: "",
            }
            this.valid=true;
            this.$refs.form.resetValidation();
          }
        },
        immediate: true
      }
    },
  
    computed: {
      maxDialogWidth() {
        return this.$clientWidth > 800 ? "600px" : "6.8rem";
      },
      emailFlag(){
          return this.form.userEmail&&/^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9]+$/.test(this.form.userEmail)
      },
      orderInfo(){
        console.log(this.$store.state.orderInfo)
          return this.$store.state.orderInfo;
      }
    },
  
    methods: {
      openDialog() {
        this.form={
          addressLine1: "",
          addressLine2: "",
          city: "",
          country: "",
          firstName: this.orderInfo.registerForm.userLoginName,
          lastName: "",
          phoneNumber: this.orderInfo.registerForm.userTelephone,
          email:this.orderInfo.registerForm.userEmail,
          state: "",
          zip: "",
        }
        this.visible = true;
        this.$refs.form.resetValidation();
      },
      validate() {
          let flag=this.$refs.form.validate();
          if(flag){
            submitGiftMessage({...this.form,orderId:this.orderInfo.orderId}).then((res)=>{
                  if(res.code=='000000'){
                      this.visible=false;
                      this.$emit('success')
                  }
              })
          }
  
      },
      reset() {
        this.$refs.form.reset();
      },
      resetValidation() {
        this.$refs.form.resetValidation();
      },
    }
  };
  </script>
  
  <style lang="scss" scoped>
  $dialog-border-radius: "5px";
  .congrate-wrapper{
        padding: 0.8rem 0.4rem 0.5rem;
        font-size: 0.26rem;
        background: rgba(0,0,0,.6);
        color: #fff;
        position: relative;
        font-size: 0.28rem;
        line-height: 1.5;
        &>div:nth-child(1){
            box-sizing: border-box;
            padding-right: 0.6rem;
            font-size: 0.28rem;
            font-weight: bold;
        }
        .comment-step{
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding-right: 1.5rem;
            margin-top: 0.2rem;
            margin-bottom: 0.2rem;
            &>div{
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                border: 1px solid #fff;
                text-align: center;
                line-height: 0.5rem;
                &.active{
                  border: 1px solid #0288d1;
                }
            }
            .v-divider{
                margin: 0 0.2rem;
            }
        }
        .close-icon{
            position: absolute;
            top:0.3rem;
            right:0.3rem;
        }
        .congrate-footer{
            text-align: center;
            display: flex;
            justify-content: space-around;
        }
        :deep(.v-textarea textarea){
            line-height: 1.5;
        }
    }
    
.register-box-header{
    padding-top: 0.3rem;
    &>div:first-child{
      text-align: center;
      color: #0162BD;
      line-height: 1;
      i{
        font-size: 0.8rem;
      }
    }
    &>div:nth-child(2){
      text-align: center;
      &>div:first-child{
        font-size: 0.28rem;
        font-weight: 500;
        color: #000000;
      }
      &>div:last-child{
        font-size: 0.28rem;
        font-weight: 400;
        color: #ADADAD;
      }
    }
  }
  .register-container{
      font-size: 12px;
      .register-wraperr{
          padding-bottom: 0.2rem;
          padding-top: 0.2rem;
      }
  }
  .register-agreement{
    font-size: 0.22rem;
    line-height: 1.2;
    color: #adadad;
    margin-bottom: 0.4rem;
    margin-top: 0.2rem;
  }
  .register-footer{
    padding-bottom: 0.8rem;
    line-height: 1.2;
  }
  .card-container{
    padding:0.4rem;
    font-size: 0.24rem;
    .card-img{
        padding: 0 0.6rem;
    }
    &>div:nth-child(1){
        font-weight: bold;
        text-align: center;
        font-size: 0.26rem;
    }
    .info-item{
        &>div:nth-child(1){
            font-weight: bold;
        }
        &>div:nth-child(2){
            color: #726f6f;
        }
        .product-name{
            height: 0.7rem;
            overflow:hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-line-clamp:2;
            -webkit-box-orient:vertical;
        }
    }
    .gift-title{
        text-align: center;
    }
  }
  @media(min-width: 800px) {
      .congrate-wrapper{
          padding: 0.8*51.282px 0.4*51.282px 0.5*51.282px;
          font-size: 0.26*51.282px;
          background: rgba(0,0,0,.6);
          color: #fff;
          position: relative;
          font-size: 0.28*51.282px;
          line-height: 1.5;
          &>div:nth-child(1){
              box-sizing: border-box;
              padding-right: 0.6*51.282px;
              font-size: 0.28*51.282px;
              font-weight: bold;
          }
          .comment-step{
              display: flex;
              justify-content: space-between;
              align-items: center;
              box-sizing: border-box;
              padding-right: 1.5*51.282px;
              margin-top: 0.2*51.282px;
              margin-bottom: 0.2*51.282px;
              &>div{
                  width: 0.5*51.282px;
                  height: 0.5*51.282px;
                  border-radius: 50%;
                  border: 1px solid #fff;
                  text-align: center;
                  line-height: 0.5*51.282px;
                  &.active{
                    border: 1px solid #0288d1;
                  }
              }
              .v-divider{
                  margin: 0 0.2*51.282px;
              }
          }
          .close-icon{
              position: absolute;
              top:0.3*51.282px;
              right:0.3*51.282px;
          }
          .congrate-footer{
              text-align: center;
              display: flex;
              justify-content: space-around;
          }
          :deep(.v-textarea textarea){
              line-height: 1.5;
          }
      }

      
      .card-container{
            padding:0.4*51.282px;
            font-size: 0.24*51.282px;
            .card-img{
                padding: 0 0.6*51.282px;
            }
            &>div:nth-child(1){
                font-weight: bold;
                text-align: center;
                font-size: 0.26*51.282px;
            }
            .info-item{
                &>div:nth-child(1){
                    font-weight: bold;
                }
                &>div:nth-child(2){
                    color: #726f6f;
                }
                .product-name{
                    height: 40px;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    display:-webkit-box;
                    -webkit-line-clamp:2;
                    -webkit-box-orient:vertical;
                }
            }
            .gift-title{
                text-align: center;
            }
        }
      .register-box-header{
        padding-top: 0.3*51.282px;
        &>div:first-child{
          text-align: center;
          color: #0162BD;
          line-height: 1;
          i{
            font-size: 0.8*51.282px;
          }
        }
        &>div:nth-child(2){
          text-align: center;
          &>div:first-child{
            font-size: 0.28*51.282px;
            font-weight: 500;
            color: #000000;
          }
          &>div:last-child{
            font-size: 0.28*51.282px;
            font-weight: 400;
            color: #ADADAD;
          }
        }
      }
      .register-container{
          font-size: 12px;
          .register-wraperr{
              padding-top: 0.1*51.282px;
          }
      }
      .register-agreement{
        font-size: 0.22*51.282px;
        line-height: 1.2;
        color: #adadad;
        margin-bottom: 0.4*51.282px;
        margin-top: 0.2*51.282px;
      }
      .register-footer{
        padding-bottom: 0.8*51.282px;
      }
  }
  </style>
  
  