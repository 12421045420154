<template>
    <div class="warranty-page">
        <div class="warranty-section section-aside" v-if="showTabs==1">
            <div class="order-info">
                <div class="order-title">My warranty</div>
                <div class="order-email">
                    <div class="container">{{userEmail}}</div>
                </div>
            </div>
            <div class="section-wrapper">
                <v-simple-table height="100%" fixed-header>
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Warranty ID
                            </th>
                            <th class="text-left">
                                Order ID
                            </th>
                            <th class="text-left">
                                register time
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="(item,index) in list"
                        :key="index"
                        >
                        <td>{{ item.warrantyId }}</td>
                        <td>{{ item.userOrderId }}</td>
                        <td>{{ item.createDate }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </div>
        </div>

        <div class="login-section section-aside" v-if="showTabs==2">
            <div class="order-info">
                <div class="order-title">My warranty</div>
            </div>

            <div class="section-wrapper">
                <div class="register-wraperr pl-6 pr-6">
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field
                        v-model="form.userEmail"
                        :maxlength="50"
                        :rules="emailRules"
                        label="Email"
                        hide-details="auto"
                        placeholder="Please enter your email"
                        required
                        ></v-text-field>
                        <v-text-field
                        v-model="form.userPass"
                        :maxlength="30"
                        :rules="pwRules"
                        label="Password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        placeholder="Please set your password"
                        required
                        validate-on-blur
                        @click:append="show1 = !show1"
                        hide-details="auto"
                        ></v-text-field>
                    </v-form>
                    <div class="sign-tips">*Please register your products in advance.</div>
                </div>
            </div>
                
            <div class="register-footer">
                <v-btn :disabled="!valid" color="primary" block @click="handleLogin">
                    Sign In
                </v-btn>
            </div>
            <div class="forgot-pw" @click="showTabs=3">Forgot password?</div>
        </div>

        <div class="reset-section  section-aside" v-if="showTabs==3">
            <div class="order-info">
                <div class="order-title">My warranty</div>
            </div>
            <div class="section-wrapper">
                <div class="register-wraperr pl-6 pr-6">
                    <v-form ref="form2" v-model="valid2" lazy-validation>
                        <v-text-field
                        v-model="form2.userEmail"
                        :maxlength="50"
                        :rules="email2Rules"
                        label="Email"
                        hide-details="auto"
                        placeholder="Please enter your email"
                        required
                        ></v-text-field>
                        <v-text-field
                        v-model="form2.userPassword"
                        :maxlength="6"
                        :rules="pw2Rules"
                        label="Password"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        placeholder="Please set your password"
                        required
                        validate-on-blur
                        @click:append="show2 = !show2"
                        hide-details="auto"
                        ></v-text-field>
                        <v-text-field
                        v-model="form2.confirmPassword"
                        :maxlength="6"
                        :rules="pw3Rules"
                        label="Confirm Password"
                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show3 ? 'text' : 'password'"
                        placeholder="Please set your password"
                        required
                        validate-on-blur
                        @click:append="show3 = !show3"
                        hide-details="auto"
                        ></v-text-field>
                        <v-text-field
                        v-model="form2.VerificationCode"
                        :maxlength="20"
                        :rules="codeRules"
                        label="Verification code"
                        placeholder="Please enter your code"
                        required
                        hide-details="auto"
                        validate-on-blur
                        >
                        <template v-slot:append-outer>
                            <v-btn
                            color="primary"
                            @click="handleGetCode"
                            :disabled="!emailFlag||timerCount < 60"
                            small
                            >
                            {{ timerCount == 60 ? "Ask for code" : `after ${timerCount}S` }}
                            </v-btn>
                        </template>
                        </v-text-field>
                    </v-form>
                    <div class="sign-tips">*Please register your products in advance.</div>
                </div>
            </div>
                
            <div class="register-footer">
                <v-btn :disabled="!valid2" color="primary" block @click="handleRegiste">
                    Submit
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import {toLoginRegister,queryRegisterUser,sendVerificationCode,modifyPassWord} from "@/api";
export default {
    data(){
        let self=this;
        return {
            showTabs: 2,// 1 list 2登录模块 3 重置密码 
            list:[],

            form:{
                userPass:'',
                userEmail:''
            },
            emailRules: [(v) => !!v || "Email cannot be empty"],
            pwRules: [(v) => !!v || "Password cannot be empty"],

            
            email2Rules: [
                (v) => !!v || "email cannot be empty",
                (v) =>
                /^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9]+$/.test(v) ||
                "the email format is incorrect",
            ],
            pw2Rules: [
                (v) => !!v || "password cannot be empty",
                (v) => (v && v.length == 6) || "password should be 6 characters",
            ],

            pw3Rules: [
                (v) => !!v || "confirm password cannot be empty",
                (v) => (v && v.length == 6) || "confirm password should be 6 characters",
                (v) =>self.checkDeffice(v)|| "The two passwords are different",
            ],
            codeRules: [
                (v) => !!v || "email verification code cannot be empty"
            ],
            show1: false,
            show2: false,
            show3: false,
            form2:{
                confirmPassword:'',
                userEmail:'',
                userPassword:'',
                VerificationCode:''
            },
            timer: null,
            timerCount: 60,
            valid2: true,
            valid: true
        }
    },
    computed:{
        userEmail(){
            return this.$store.state.userEmail;
        },
        emailFlag(){
            return this.form2.userEmail&&/^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9]+$/.test(this.form2.userEmail)
        }
    },
    methods:{
        checkDeffice(v){
            return (v==this.form2.userPassword)
        },

        handleLogin(){
            let flag=this.$refs.form.validate();
            if(flag){
                toLoginRegister(this.form).then((res)=>{
                    this.$store.commit('SET_USER_EMAIL',this.form.userEmail)
                    if (res.code == '000000') {
                        localStorage.setItem('token',res.data)
                        this.getWarrantyList()
                    }
                })
            }
        },

        getWarrantyList(){
            queryRegisterUser().then((res)=>{
                if (res.code == '000000') {
                    this.list=res.data||[]
                    this.showTabs=1
                }
            }).catch((res)=>{
                if(res.code=='920005'){
                    this.showTabs=2
                }
            })
        },
        handleGetCode() {
            if(this.emailFlag){
                this.timerCount--;
                sendVerificationCode(this.form2.userEmail,'modify').then((res)=>{
                    this.$store.dispatch('openSnackbar',{
                        msg:`The verification code has been sent to your <strong>${this.form2.userEmail}</strong> email, please check it`,
                        color:'primary'
                    })
                    this.timer = setInterval(() => {
                        this.timerCount--;
                        if (this.timerCount == 0) {
                            clearInterval(this.timer);
                            this.timerCount = 60;
                        }
                    }, 1000);
                }).catch((err)=>{
                    this.timerCount = 60;
                })
            }else{
                return;
            }
            
        
        },

        handleRegiste(){
            let flag=this.$refs.form2.validate();
            if(flag){
                modifyPassWord(this.form2).then((res)=>{
                    if (res.code == '000000') {
                        this.showTabs=2
                    }
                })
            }
            
        }
    },

 

    destroyed() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },

    created(){
        const token=localStorage.getItem('token')
        if(token){
            this.getWarrantyList()
        }else{
            this.showTabs=2;
        }
    }
}

</script>

<style lang="scss" scoped>
@media(max-width: 801px) {
    .warranty-page{
        padding: 0.2rem;
        box-sizing: border-box;
        font-size: 12px;
        height: 100vh;
        overflow: hidden;
        .section-aside{
            height: 100%;
            display: flex;
            flex-direction: column;
            .section-wrapper{
                flex: 1;
                height: 0;
                overflow-y:auto;
            }
        }
    }
    .order-title{
        font-size: 0.6rem;
        font-weight: bold;
        text-align: center;
        margin-top:0.4rem;
        margin-bottom: 0.2rem;
    }
    .order-email{
        .container{
            font-size: 0.2rem;
            line-height: 0.6rem;
            border-bottom: 1px solid #00a7de;
            display: inline-block;
        }
    }
    th,td{
        font-size: 0.28rem!important;
        height: 0.5rem!important;
    }
    .register-footer{
        padding: 0.4rem 0;
    }
    .register-container{
        padding: 0.8rem 0 0.8rem;
    }
    .sign-tips{
        margin-top: 0.4rem;
    }
    .forgot-pw{
        padding-bottom: 0.4rem;
        font-size: 0.3rem;
    }
}

@media(min-width: 800px) {
    .warranty-page{
        padding: 20px;
        width: 100%;
        margin: 0 auto;
        max-width: 800px;
        box-sizing: border-box;
        font-size: 12px;
        height: 100vh;
        overflow: hidden;
        .section-aside{
            height: 100%;
            display: flex;
            flex-direction: column;
            .section-wrapper{
                flex: 1;
                height: 0;
                overflow-y:auto;
            }
        }
    }
    .order-title{
        font-size: 0.6*51.282px;
        font-weight: bold;
        text-align: center;
        margin-top:0.4*51.282px;
        margin-bottom: 0.2*51.282px;
    }
    .order-email{
        .container{
            font-size: 0.2*51.282px;
            line-height: 0.6*51.282px;
            border-bottom: 1px solid #00a7de;
            display: inline-block;
        }
    }

    .register-footer{
        padding: 0.4*51.282px 0;
    }
    .register-container{
        padding: 0.8*51.282px 0 0.8*51.282px;
    }
    .sign-tips{
        margin-top: 0.4*51.282px;
    }
    .forgot-pw{
        padding-bottom: 0.4*51.282px;
        font-size: 0.3*51.282px;
    }
}
</style>