<template>
    <v-dialog
      transition="dialog-top-transition"
      :max-width="maxDialogWidth"
      persistent
      v-model="visible"
      content-class="congrate-dialog"
    >   
        <div class="congrate-wrapper">
            <div>
                Have you copied and posted your review on Amazon?
            </div>
            
            <div class="congrate-footer">
                <div><v-btn rounded color="warning" @click="handleConfirmAmazon(true)">YES</v-btn></div>
                <div><v-btn rounded dark  color="warning" @click="handleConfirmAmazon()">No</v-btn></div>
            </div>
            
            <div class="close-icon" @click="visible=false">
                <v-icon color="#fff">mdi-close-thick</v-icon>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import {updateOrderSchedule} from "@/api/index.js";
export default {
    data(){
        return {
            visible: false
        }
    },
    computed: {
        maxDialogWidth() {
            return this.$clientWidth > 800 ? "600px" : "6.4rem";
        },
        orderInfo(){
            return this.$store.state.orderInfo;
        }
    },

    methods:{
        handleConfirmAmazon(flag=false){
            if(flag){
                updateOrderSchedule({
                    orderId: this.orderInfo.orderId,
                    reviewFlag: true,
                    schedule: 5
                }).then((res)=>{
                    this.visible=false;
                    this.$emit('success')
                })
            }else{
                updateOrderSchedule({
                    orderId: this.orderInfo.orderId,
                    reviewFlag: false,
                }).then((res)=>{
                    this.visible=false;
                    this.$emit('success')
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .congrate-wrapper{
        padding: 0.8rem 0.4rem 0.5rem;
        font-size: 0.26rem;
        background: rgba(0,0,0,.6);
        color: #fff;
        position: relative;
        font-size: 0.28rem;
        line-height: 1.5;
        &>div:nth-child(1){
            box-sizing: border-box;
            font-size: 0.28rem;
            font-weight: bold;
            text-align: center;
        }
        
        .close-icon{
            position: absolute;
            top:0.3rem;
            right:0.3rem;
        }
        .congrate-footer{
            text-align: center;
            display: flex;
            justify-content: center;
            margin-top: 0.5rem;
            &>div:nth-child(1){
                margin-right: 0.6rem;
            }
        }
        :deep(.v-textarea textarea){
            line-height: 1.5;
        }
    }

    @media(min-width: 800px) {
        .congrate-wrapper{
            padding: 1*51.282px 0.4*51.282px 0.5*51.282px;
            font-size: 0.26*51.282px;
            background: rgba(0,0,0,.6);
            color: #fff;
            position: relative;
            font-size: 0.28*51.282px;
            line-height: 1.5;
            &>div:nth-child(1){
                box-sizing: border-box;
                font-size: 0.32*51.282px;
                font-weight: bold;
                text-align: center;
            }
            
            .close-icon{
                position: absolute;
                top:0.3*51.282px;
                right:0.3*51.282px;
            }
            .congrate-footer{
                text-align: center;
                display: flex;
                justify-content: center;
                margin-top: 1.2*51.282px;
                &>div:nth-child(1){
                    margin-right: 0.6*51.282px;
                }
            }
            :deep(.v-textarea textarea){
                line-height: 1.5;
            }
        }
    }
</style>