<template>
    <v-dialog
      transition="dialog-top-transition"
      :max-width="maxDialogWidth"
      persistent
      v-model="visible"
      content-class="congrate-dialog"
    >   
        <div class="congrate-wrapper">
            <div>
                Step 1 of 3: Tell Us Your Experience About lt
            </div>
            <div class="comment-step">
                <div class="active">1</div>
                <v-divider color="#fff"></v-divider>
                <div>2</div>
                <v-divider color="#fff"></v-divider>
                <div>3</div>
            </div>

            <div class="mt-2">
                <div>Overall rating?*</div>
                <div>
                    <v-rating
                    color="warning"
                    dense
                    hover
                    length="5"
                    :size="maxDialogWidth==='600px'?'50px':'0.9rem'"
                    v-model="commentRating"
                    ></v-rating>
                </div>
            </div>

            <div>
                <div>Let's know your comment about the product.*</div>
                <div class="mt-2">
                    <v-textarea
                    v-model="commentDesc"
                    solo
                    :row-height="maxDialogWidth==='600px'?'30px':'0.2rem'"
                    placeholder="Please enter at least 3 characters."
                    rows="5"
                    ></v-textarea>
                </div>
            </div>
            <div class="congrate-footer">
                <!-- <div><v-btn :block="false" color="warning">PREV STEP</v-btn></div> -->
                <div><v-btn :block="false" color="warning" @click="handleResolveComment">NEXT STEP</v-btn></div>
            </div>

            
            <div class="close-icon" @click="visible=false">
                <v-icon color="#fff">mdi-close-thick</v-icon>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import {updateOrderSchedule} from "@/api/index.js";
export default {
    data(){
        return {
            visible: false,
            commentDesc:'',
            commentRating:0
        }
    },
    computed: {
        maxDialogWidth() {
            return this.$clientWidth > 800 ? "600px" : "6.4rem";
        },
    },

    watch:{
        'visible':{
            handler(val){
                if(!val){
                    this.commentDesc='';
                    this.commentRating=0    
                }
            },
            immediate: true
        }
    },

    methods:{
        handleResolveComment(){
            if(!this.commentRating){
                return this.$store.dispatch('openSnackbar',{
                    msg:`The Overall rating cant be empty!`,
                    color:'warning'
                })
            }
            if(!this.commentDesc){
                return this.$store.dispatch('openSnackbar',{
                    msg:`The comment cant be empty!`,
                    color:'warning'
                })
            }
            if(this.commentDesc.length<3){
                return this.$store.dispatch('openSnackbar',{
                    msg:`The comment at least 3 characters!`,
                    color:'warning'
                })
            }
            updateOrderSchedule({
                orderId: this.$store.state.orderInfo.orderId,
                pointStarNum: this.commentRating,
                review:this.commentDesc,
                schedule: 3
            }).then((res)=>{
                this.$store.commit('SET_ORDER_INFO',{
                    ...this.$store.state.orderInfo,
                    pointStarNum: this.commentRating,
                    review:this.commentDesc
                })
                this.visible=false;
                this.$emit('success',this.commentRating)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .congrate-wrapper{
        padding: 0.8rem 0.4rem 0.5rem;
        font-size: 0.26rem;
        background: rgba(0,0,0,.6);
        color: #fff;
        position: relative;
        font-size: 0.28rem;
        line-height: 1.5;
        &>div:nth-child(1){
            box-sizing: border-box;
            padding-right: 0.6rem;
            font-size: 0.28rem;
            font-weight: bold;
        }
        .comment-step{
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding-right: 1.5rem;
            margin-top: 0.2rem;
            margin-bottom: 0.2rem;
            &>div{
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                border: 1px solid #fff;
                text-align: center;
                line-height: 0.5rem;
                &.active{
                  border: 1px solid #0288d1;
                }
            }
            .v-divider{
                margin: 0 0.2rem;
            }
        }
        .close-icon{
            position: absolute;
            top:0.3rem;
            right:0.3rem;
        }
        .congrate-footer{
            text-align: center;
            display: flex;
            justify-content: space-around;
        }
        :deep(.v-textarea textarea){
            line-height: 1.5;
        }
    }
    @media(min-width: 800px) {
        .congrate-wrapper{
            padding: 0.8*51.282px 0.4*51.282px 0.5*51.282px;
            font-size: 0.26*51.282px;
            background: rgba(0,0,0,.6);
            color: #fff;
            position: relative;
            font-size: 0.28*51.282px;
            line-height: 1.5;
            &>div:nth-child(1){
                box-sizing: border-box;
                padding-right: 0.6*51.282px;
                font-size: 0.28*51.282px;
                font-weight: bold;
            }
            .comment-step{
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                padding-right: 1.5*51.282px;
                margin-top: 0.2*51.282px;
                margin-bottom: 0.2*51.282px;
                &>div{
                    width: 0.5*51.282px;
                    height: 0.5*51.282px;
                    border-radius: 50%;
                    border: 1px solid #fff;
                    text-align: center;
                    line-height: 0.5*51.282px;
                    &.active{
                    border: 1px solid #0288d1;
                    }
                }
                .v-divider{
                    margin: 0 0.2*51.282px;
                }
            }
            .close-icon{
                position: absolute;
                top:0.3*51.282px;
                right:0.3*51.282px;
            }
            .congrate-footer{
                text-align: center;
                display: flex;
                justify-content: space-around;
            }
            :deep(.v-textarea textarea){
                line-height: 1.5;
            }
        }
    }
</style>