<template>
  <v-dialog
    transition="dialog-top-transition"
    :max-width="maxDialogWidth"
    persistent
    v-model="visible"
    content-class="register-dialog"
  >
    <v-card class="register-container">
      <div class="register-box-header pl-6 pr-6">
        <div>
          <i class="iconfont icon-c-xinrenzhuce"></i>
        </div>
        <div>
          <div>Activate your warranty and Join our member.</div>
          <div>New member will receive a free gift.</div>
        </div>
        <div class="close-icon" @click="visible=false">
            <v-icon>mdi-close-thick</v-icon>
        </div>
      </div>
      <div class="register-wraperr pl-6 pr-6">
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
            v-model="form.userLoginName"
            :maxlength="50"
            :rules="nameRules"
            label="Name"
            hide-details="auto"
            placeholder="Please enter your name"
            required
            ></v-text-field>
            <v-text-field
            v-model="form.userEmail"
            :maxlength="30"
            :rules="emailRules"
            label="Email"
            hide-details="auto"
            validate-on-blur
            placeholder="Please enter your email"
            required
            ></v-text-field>
            <v-text-field
            v-if="!existFlag"
            v-model="form.userPassword"
            :maxlength="20"
            :rules="pwRules"
            label="Password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            placeholder="Please set your password"
            required
            validate-on-blur
            ref="userPw"
            @click:append="show1 = !show1"
            hide-details="auto"
            ></v-text-field>
            <v-text-field
            v-else
            v-model="form.userPassword"
            :maxlength="20"
            label="Password"
            :type="'password'"
            readonly
            hide-details="auto"
            ></v-text-field>
            <v-text-field
            v-model="form.userTelephone"
            :maxlength="20"
            :rules="phoneRules"
            label="Phone Number"
            validate-on-blur
            placeholder="Please enter your phone number"
            hide-details="auto"
            ></v-text-field>
            <v-text-field
            v-model="form.userOrderId"
            :maxlength="20"
            :rules="orderIdRules"
            label="Order ID"
            placeholder="Please enter your ID"
            required
            hide-details="auto"
            validate-on-blur
            @focus="errorTipsFlag=false"
            
            >
            <template v-slot:append-outer>
                <v-icon @click="handleFindOrder">mdi-help-circle-outline</v-icon>
            </template>
            </v-text-field>
            <div class="error-tips" v-if="errorTipsFlag">
              <span><v-icon color="red" :size="maxDialogWidth=='600px'?'16px':'0.28rem'">mdi-alert-circle</v-icon>We Can't find your order ID<br/></span>
              <span>If any questions, please contact us.<br/></span>
              <span>{{$store.state.brandMail}}</span>
            </div>
            <v-text-field
            v-model="form.VerificationCode"
            :maxlength="20"
            :rules="codeRules"
            label="Verification code"
            placeholder="Please enter your code"
            required
            hide-details="auto"
            validate-on-blur
            ref="verificationCode"
            >
            <template v-slot:append-outer>
                <v-btn
                color="primary"
                @click="handleGetCode"
                :disabled="!emailFlag||timerCount < 60"
                small
                >
                {{ timerCount == 60 ? "Ask for code" : `after ${timerCount}S` }}
                </v-btn>
            </template>
            </v-text-field>
        </v-form>
      </div>
      <div class="pl-6 pr-6 register-footer">
        <div class="register-agreement">
            *Any personal information you provide to us is maintained in private
            files on our secure Web server and our internal systems.Will be kept
            strictly confidential and will not be distributed to any company or
            individual.
        </div>
        <v-btn :disabled="!valid" color="primary" block @click="validate">
        Activate Now
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { registerInsertPO,sendVerificationCode,checkRegisterUser} from "@/api/index.js";
export default {
  data() {
    return {
      visible: true,
      valid: true,
      form: {
        userEmail: "",
        userLoginName: "",
        userTelephone: "",
        userPassword: "",
        userOrderId: "",
        exitsFlag: false,
        VerificationCode: "",
      },
      nameRules: [(v) => !!v || "name cannot be empty"],
      codeRules: [
        (v) => !!v || "email verification code cannot be empty",
        (v) => /^[0-9]+$/.test(v) || "email verification code is error",
      ],
      emailRules: [
        (v) => !!v || "email cannot be empty",
        (v) =>
          /^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9]+$/.test(v) ||
          "the email format is incorrect",
      ],
      pwRules: [
        (v) => !!v || "password cannot be empty",
        (v) => (v && v.length > 5) || "password at least 6 characters",
      ],
      phoneRules: [
        (v) => (!v||/^[0-9-+()]+$/.test(v)) || "the telephone format is incorrect",
      ],
      orderIdRules: [
        (v) => !!v || "orderId cannot be empty",
        (v) =>{
          let _v=v.trim();
          return (/^[a-zA-Z0-9]{3}\-\d{7}\-\d{7}$/.test(_v) || "the OrderID format is incorrect")
        }
          
      ],
      timer: null,
      timerCount: 60,
      show1: false,
      existFlag: false,
      errorTipsFlag: false
    };
  },

  computed: {
    maxDialogWidth() {
      return this.$clientWidth > 800 ? "600px" : "6.8rem";
    },
    emailFlag(){
        return this.form.userEmail&&/^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9]+$/.test(this.form.userEmail)
    }
  },

  watch:{
    'visible':{
      handler(val){
        if(!val){
          this.form={
            userEmail: "",
            userLoginName: "",
            userTelephone: "",
            userPassword: "",
            userOrderId: "",
            exitsFlag: false,
            VerificationCode: "",
          }
          if(this.timer){
            clearInterval(this.timer);
          }
          this.valid=true;
          this.timer=null;
          this.timerCount=60;
          this.show1=false;
          this.existFlag=false;
          this.errorTipsFlag=false;
          this.$refs.form.resetValidation();
        }
      },
      immediate: true
    }
  },

  methods: {
    openDialog() {
      this.visible = true;
    },
    validate() {
        let flag=this.$refs.form.validate();
        if(flag){
            registerInsertPO({...this.form,userOrderId:this.form.userOrderId.trim(),exitsFlag:this.exitsFlag?1:0}).then((res)=>{
                if(res.code=='000000'){
                    localStorage.setItem('token',res.data.token)
                    this.$store.commit('SET_USER_EMAIL',this.form.userEmail)
                    if(res.data.configByAisn){
                      this.$store.commit('SET_ORDER_INFO',{...res.data.configByAisn,orderId: this.form.userOrderId,registerForm:{
                        ...this.form
                      }})
                      this.visible=false;
                      this.$emit('success')
                    }else{
                      this.$store.dispatch('openSnackbar',{
                        msg:`The order is Actived Success！`,
                        color:'success',
                        showClose: true,
                        timeout: 5000,
                      })
                      this.visible=false;
                    }
                }else if(res.code=='920042'){
                      this.visible=false;
                      this.$emit('registed')
                }else{
                  this.$store.dispatch('openSnackbar',{
                    msg:`We Can't find your order ID<br/>If any questions, please contact us.<br/> <strong>${this.$store.state.brandMail}</strong>`,
                    color:'red',
                    showClose: true,
                    timeout: 5000,
                  })
                  this.errorTipsFlag=true
                }
            })
        }

    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    handleGetCode() {
        this.$refs.verificationCode.focus()
        if(this.emailFlag){
            this.timerCount--;
            sendVerificationCode(this.form.userEmail).then((res)=>{
                this.$store.dispatch('openSnackbar',{
                    msg:`The verification code has been sent to your <strong>${this.form.userEmail}</strong> email, please check it`,
                    color:'primary'
                })
                this.timer = setInterval(() => {
                    this.timerCount--;
                    if (this.timerCount == 0) {
                        clearInterval(this.timer);
                        this.timerCount = 60;
                    }
                }, 1000);
            }).catch((err)=>{
              this.timerCount = 60;
            })
        }else{
            return;
        }
    },
    checkRegister(val){
        if(this.emailFlag){
            checkRegisterUser(this.form.userEmail).then((res)=>{
                if(res.data){
                    this.existFlag=true
                    this.form.userPassword=res.data.userPassword;
                }else{
                    this.existFlag=false
                }
            })
        }
    },

    handleFindOrder(){
      const href = this.$router.resolve({
        path: `/order-step`,
      }).href;
      //打开新的页面 到 href 这个页面
      window.open(href, "_blank");
    }
  },

 

  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
$dialog-border-radius: "5px";
.register-dialog{
    .close-icon{
        position: absolute;
        top:0.3rem;
        right:0.3rem;
    }
    .register-box-header{
      padding-top: 0.3rem;
      &>div:first-child{
        text-align: center;
        color: #0162BD;
        line-height: 1;
        i{
          font-size: 0.8rem;
        }
      }
      &>div:nth-child(2){
        text-align: center;
        &>div:first-child{
          font-size: 0.28rem;
          font-weight: 500;
          color: #000000;
        }
        &>div:last-child{
          font-size: 0.28rem;
          font-weight: 400;
          color: #ADADAD;
        }
      }
    }
    .register-container{
        font-size: 12px;
        .register-wraperr{
            padding-top: 0.1rem;
        }
    }
    .register-agreement{
      font-size: 0.22rem;
      line-height: 1.2;
      color: #adadad;
      margin-bottom: 0.4rem;
      margin-top: 0.2rem;
    }
    .register-footer{
      padding-bottom: 0.8rem;
    }
    .error-tips{
      font-size: 0.28rem;
      &>span:nth-child(1){
        color: red;
      }
      &>span:nth-child(3){
        color: #0288d1;
      }
    }
}
@media(min-width: 800px) {
  .register-dialog{
    .close-icon{
        position: absolute;
        top:0.3*51.282px;
        right:0.3*51.282px;
    }
    .register-box-header{
      padding-top: 0.3*51.282px;
      &>div:first-child{
        text-align: center;
        color: #0162BD;
        line-height: 1;
        i{
          font-size: 0.8*51.282px;
        }
      }
      &>div:nth-child(2){
        text-align: center;
        &>div:first-child{
          font-size: 0.28*51.282px;
          font-weight: 500;
          color: #000000;
        }
        &>div:last-child{
          font-size: 0.28*51.282px;
          font-weight: 400;
          color: #ADADAD;
        }
      }
    }
    .register-container{
        font-size: 12px;
        .register-wraperr{
            padding-top: 0.1*51.282px;
        }
    }
    .register-agreement{
      font-size: 0.22*51.282px;
      line-height: 1.2;
      color: #adadad;
      margin-bottom: 0.4*51.282px;
      margin-top: 0.2*51.282px;
    }
    .register-footer{
      padding-bottom: 0.8*51.282px;
    }
}
    .error-tips{
      font-size: 16px!important;
      &>span:nth-child(1){
        color: red;
      }
      &>span:nth-child(3){
        color: #0288d1;
      }
    }
}
</style>

