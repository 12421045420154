import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from './router'
import store from './store'
import "@/assets/css/base.css"
Vue.config.productionTip = false;
import "swiper/dist/css/swiper.min.css"
import "@/utils/flexible.js";
//引入组件库
import jvuewheel from '@jyeontu/jvuewheel'
//引入样式
import '@jyeontu/jvuewheel/lib/jvuewhell.css'
Vue.use(jvuewheel);
import "@/assets/icon/iconfont.css"
const clientWidth=document.documentElement.clientWidth;
Vue.prototype.$clientWidth=clientWidth;



new Vue({
  vuetify,
  store,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
