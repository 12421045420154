<template>
    <div class="tips-page">
        <div>
            <img src="../../assets/tips.jpg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            
        }
    }
}
</script>

<style lang="scss" scoped>
    .tips-page{
        background: #efefef;
        padding: 0.24rem;
        box-sizing: border-box;
        position: relative;
        flex: 1;
        overflow-y: auto;
        color: #000000;
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        img{
            width: 100%;
        }
    }
</style>