import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state() {
    return {
      snackbar: {
        msg: '',
        color: '',
        visible: false,
        showClose: true,
        timeout: 3000,
      }, 
      orderInfo:null,
      userEmail:'',
      overlay: false,//loading遮罩层
      brandMail:'',
      brandTypeName:''
    };
  },
  getters: {
  },
  mutations: {
    OPEN_SNACKBAR(state, options) {
      state.snackbar.visible = true
      state.snackbar.msg = options.msg
      state.snackbar.color = options.color
    },
    CLOSE_SNACKBAR(state) {
      state.snackbar.visible = false
    },
    setShowClose(state, isShow) {
      state.snackbar.showClose = isShow
    },
    setTimeout(state, timeout) {
      state.snackbar.timeout = timeout
    },

    SET_ORDER_INFO(state,orderInfo){
      state.orderInfo=orderInfo;
    },

    SET_USER_EMAIL(state,userEmail){
      state.userEmail=userEmail;
    },

    SET_MASK_OVERLAY(state,overlay){
      state.overlay=overlay;
    },

    SET_BRAND_EMAIL(state,brandMail){
      state.brandMail=brandMail;
    },

    SET_BRAND_TYPE_NAME(state,brandTypeName){
      state.brandTypeName=brandTypeName;
    },
  },
  actions: {
    tigglerMask(context,overlay){
      context.commit('SET_MASK_OVERLAY',overlay)
    },
    openSnackbar (context,options){
      let timeout = context.state.snackbar.timeout
      context.commit('OPEN_SNACKBAR',{
        msg:options.msg,
        color:options.color
      })
      setTimeout(()=>{
        context.commit('CLOSE_SNACKBAR')
      },timeout)
    }
  },
});
