<template>
    <v-dialog
      transition="dialog-top-transition"
      :max-width="maxDialogWidth"
      persistent
      v-model="visible"
      content-class="congrate-dialog"
    >   
        <div class="congrate-wrapper">
            <div>
                <div><v-icon large color="success">mdi-checkbox-marked-circle</v-icon></div>
                Finished!
            </div>

            <div>
                <br/>You've finished the free gift application.<br/>
                The free gift will be shipped in 2-5 working days.<br/><br/>
                lf any questions, please contact us.<br/>
            </div>
            <div class="email">
                {{$store.state.orderInfo.mailbox||$store.state.brandMail}}
            </div>
            
            <div class="close-icon" @click="visible=false">
                <v-icon color="#fff">mdi-close-thick</v-icon>
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            visible: false
        }
    },
    computed: {
        maxDialogWidth() {
            return this.$clientWidth > 800 ? "450px" : "6.4rem";
        },
    },

    methods:{
    }
}
</script>

<style lang="scss" scoped>
    .congrate-wrapper{
        padding: 0.8rem 0.8rem 0.5rem 1.2rem;
        font-size: 0.26rem;
        background: rgba(0,0,0,.6);
        color: #fff;
        position: relative;
        font-size: 0.28rem;
        line-height: 1.5;
        min-height: 40vh;
        &>div:nth-child(1){
            box-sizing: border-box;
            font-size: 0.3rem;
            font-weight: bold;
            text-align: left;
            position: relative;
            line-height: 0.7rem;
            &>div{
                position: absolute;
                left: -0.8rem;
                top: 0;
            }
        }
        &>div:nth-child(3){
            box-sizing: border-box;
            font-size: 0.28rem;
            font-weight: bold;
            text-align: left;
            color: #0162BD;
        }
        
        .close-icon{
            position: absolute;
            top:0.3rem;
            right:0.3rem;
        }
    }

    @media(min-width: 800px) {
        .congrate-wrapper{
            padding: 0.8*51.282px 50px 0.5*51.282px 100px;
            font-size: 0.26*51.282px;
            background: rgba(0,0,0,.6);
            color: #fff;
            position: relative;
            font-size: 0.28*51.282px;
            line-height: 1.5;
            min-height: 40vh;
            &>div:nth-child(1){
                box-sizing: border-box;
                font-size: 18px;
                font-weight: bold;
                text-align: left;
                position: relative;
                line-height: 40px;
                &>div{
                    position: absolute;
                    left: -50px;
                    top: 0;
                }
            }
            &>div:nth-child(3){
                box-sizing: border-box;
                font-size: 16px;
                font-weight: bold;
                text-align: left;
                color: #0162BD;
            }
            
            .close-icon{
                position: absolute;
                top:0.3*51.282px;
                right:0.3*51.282px;
            }
        }
    }
</style>